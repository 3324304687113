<template>
  <b-modal id="modalMaterial" :title="$t('Solicitud de materiales')" modal-class="custom-dialog dialog-800"
  :hide-footer="showRequest" ok-only no-close-on-esc no-close-on-backdrop centered @close="close">
    <div v-if="alertCarrier.show" class="col-md-12">
      <b-alert class="p-1" variant="warning" show>
        <div class="d-flex justify-content-center align-items-center">
          <feather-icon size="30" class="mr-50" icon="InfoIcon"/>
          <span>Para solicitar materiales con este operador se requiere un mínimo de <strong>{{ alertCarrier.n_deliveries }}</strong> envíos por mes. Sino se cumple, se desestimara la solicitud.</span>
        </div>
      </b-alert>
    </div>
    <form-render v-show="!loading.modal" :form.sync="form" :fields="fields" @send="onAccept" containerButtonsClass="col-sm-12" ref="formRenderMaterial" :key="keyFormRender">
    <template #organization>
      <label title="Organización" class="label-form"><span>{{$t('Organización')}}</span><span class="text-danger"> *</span></label>
      <input type="text" value="Prueba" class="form-control" disabled>
    </template>
    <template #line>
      <hr class="mt-1">
    </template>
    <!-- <template #provider>
      <label title="Proovedor" class="label-form"><span>{{$t('Proovedor')}}</span><span class="text-danger"> *</span></label>
      <input type="text" :value="nameProvider" class="form-control" disabled>
    </template> -->
    </form-render>
    <div class="col-md-12" v-show="showAlertRangeMaterials">
      <small class="text-danger">{{$t('Oops, Al parecer hay un error en uno o más de los campos completados.')}}</small>
    </div>
    <div v-show="loading.modal">
      <b-skeleton width="30%"/>
      <b-skeleton type="input"/>
      <b-skeleton width="30%" class="mt-1"/>
      <b-skeleton type="input"/>
    </div>
    
    <!-- Footer modal materials -->
    <hr class="mt-1">
    <div class="row justify-content-center align-items-center">
      <div v-if="showAlertFooter" class="col-md-9">
        <b-alert class="p-1" variant="primary" show>
          <div class="d-flex justify-content-center align-items-center">
            <feather-icon size="30" class="mr-50" icon="InfoIcon"/>
            <span>{{ $t(msgModalMaterialsFooter) }}</span>
          </div>
        </b-alert>
      </div>
      <div :class="showAlertFooter ? 'col-md-3' : 'col-md-12'">
        <div class="push-right">
          <b-button @click="ok" :disabled="loading.btn" variant="warning">Solicitar</b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import MaterialsService from './materials.service'
import { mapGetters } from 'vuex'

export default {
  name:'modalMaterial',
  props: ['material'],
  data () {
    return {
      form: {},
      fields: [],
      loading: {
        btn: true,
        modal: true
      },
      showAlertRangeMaterials:false,
      showAlertFooter: false,
      alertCarrier: {
        show: false,
        n_deliveries: null
      },
      showRequest: true,
      msgModalMaterialsFooter: '',
      keyFormRender: 0,
      carrierFields: [],
      ROLES : {
        marketplace: 'marketplace',
        ecommerce: 'ecommerce',
        admin: 'admin',
        superadmin: 'superadmin'
      },
      countriesData: {
        CL: {
          phone_code:56,
          phone_digits:9
        },
        MX: {
          phone_code:52,
          phone_digits:10
        },
        CO: {
          phone_code:57,
          phone_digits:13
        },
        PE: {
          phone_code:51,
          phone_digits:9
        }
      },
      materialsService: new MaterialsService(this)
    }
  },
  // Endpoints globales
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession() {
      if (this.mySession.id) this.setInitialData()
    },
    material () {
      this.form = {
        ...this.material
      }
      this.loading.btn = false
      this.showAlertFooter = false
      this.alertCarrier.show = false
      this.loading.modal = false
      this.keyFormRender++
      this.setInitialData()
    },
    'form.shipper' () {
      if (!this.form.shipper) {
        this.form.warehouse = null
        this.form.carrier = null
      } else if (this.form.shipper.country) {
        const countryCode = this.form.shipper.country.code
        if (this.countriesData[countryCode]) {
          const phoneCode = this.countriesData[countryCode].phone_code
          const phoneDigits = this.countriesData[countryCode].phone_digits
          this.fields[this.selectionIndex('phone')].prepend = { text: `+${phoneCode}` }
          this.fields[this.selectionIndex('phone')].max = phoneDigits
        }
      } else {
        // Si no hay 'country' en 'this.form.shipper'
        this.fields[this.selectionIndex('phone')].prepend = { text: '+', color: '#BBBBBB' }
        delete this.fields[this.selectionIndex('phone')].max
      }
    }
  },
  mounted () {
    this.getAllInitialData()
  },
  methods: {
    getAllInitialData() {
      if (this.mySession.id) this.setInitialData()
    },
    setInitialData() {
      const warehouseField = {
        fieldType: 'FieldSelect', 
        name: 'warehouse', 
        label: 'Dirección de entrega', 
        containerClass: 'col-sm-12 col-md-4 container-info', 
        // searchOnType: { fx: this.getWarehousesByShipper, nChars: 3 }, 
        validation: 'required'
      }
      const carrierField =  {
        fieldType: 'FieldSelect', 
        name: 'carrier', 
        label: 'Courier', 
        containerClass: 'col-sm-12 col-md-4 container-info',  
        validation: 'required', 
        change: this.changeCarrier
      }
      this.fields = [
        carrierField,
        warehouseField,
        {
          fieldType: 'FieldInput', 
          name: 'name', 
          label: 'Nombre contacto', 
          containerClass: 'col-sm-12 col-md-4 container-info', 
          validation: 'required'
        },
        {
          fieldType: 'FieldInput', 
          name: 'phone', 
          type: 'number',
          msg: {text: 'dasda'},
          label: 'Teléfono de contacto', 
          containerClass: 'col-sm-12 col-md-4 container-info', 
          validation: 'required',
          prepend: { text: '+', color: '#BBBBBB' }
        }
      ]
      // Objeto con condiciones segun el rol para activar campos
      const option = this.materialsService.conditionForRolFields(this.mySession.role)
      if (option.show_shipper) {
        const shipperField = {
          fieldType: 'FieldSelect', 
          name: 'shipper', 
          label: 'Empresa', 
          containerClass: 'col-sm-12 col-md-4 container-info', 
          validation: 'required',
          searchOnType: { fx: this.searchShippers, nChars: 3 }, 
          change: this.changeShipper
        }
        this.fields.unshift(shipperField)
        warehouseField.dependency = 'shipper'
        carrierField.dependency = 'shipper'
      } else {
        this.form.shipper = this.mySession.shipper
        this.changeShipper('shipper', this.form.shipper)
      }
    },

    // Buscamos el indice de un campo segun su nombre
    selectionIndex(name) {
      return this.fields.findIndex(el => el.name === name)
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderMaterial.checkForm()
    },
    close () {
      this.$emit('update:material', {})
    },
    onAccept (data) {
      this.loading.btn = true
      const { first_name, last_name, email, role } = this.mySession
      const form = {
        company_id: data.shipper?.id ? data.shipper.id : this.form.shipper.id,
        warehouse_id: data.warehouse.id,
        carrier_code: data.carrier.code,
        user: {
          name: `${first_name} ${last_name}`,
          email,
          role
        },
        contact:{
          name: data.name,
          phone: data.phone
        },
        materials: {}
      }
      for (const field of this.carrierFields) {
        form.materials[field.name] = data[field.name]
      }
      this.$emit('send', { form, original: data})
    },
    // Condicionamos que campos debe ver el usuario segun el carrie que seleccione
    changeCarrier(name, value) {
      if (!value) {
        this.fields = this.fields.filter(field => !field.isMaterialsField)
        this.showAlertFooter = false
        this.alertCarrier.show = false
        this.loading.btn = false
        return
      }

      if (this.mySession.role === this.ROLES.ecommerce) this.msgModalMaterialsFooter = 'msg-alert-combination-carrier'
      else this.msgModalMaterialsFooter = 'msg-alert-combination-shipper-carrier'
      
      if (this.form.carrier?.block_materials) {
        this.showAlertFooter = true
        this.loading.btn = true
      } else {
        this.showAlertFooter = false
        this.loading.btn = false
      }
      // Función que elimina los materiales no correspondientes al carrier seleccionado
      this.clearMaterialFields()

      const configuration = value.materials_configuration
      let carrierFields = configuration.fields.map(el => (({
        fieldType: 'FieldInput',
        isMaterialsField: true,
        name: el.property,
        label: this.$t('dynamic-material-label', { 'material-label':el.label }),
        minVal: el.min,
        maxVal: el.max,
        textHelp: `Ingrese un valor entre ${el.min} - ${el.max}`,
        specialVerification: this.verifyRangeMaterial,
        containerClass: 'col-sm-12 col-md-4 container-info',
        type: 'number',
        placeholder: '0'
      })))

      // Campo para un salto de linea en el formulario
      carrierFields.unshift({ 
        name: 'line', 
        useSkeleton: true, 
        isMaterialsField: true, 
        skipLine: true, 
        useSlot: true, 
        containerClass: 'col-12' 
      })
      // Si block_materials es true limpiamos el arraglo de campos dinamocos
      if (this.form.carrier?.block_materials) carrierFields = []
      
      this.fields.splice(this.selectionIndex('phone') + 1, this.carrierFields.length, ...carrierFields)
      this.carrierFields = carrierFields

      // Seteo de alerta para COO y DPR
      this.setCarrierAlert(value)
    },
    /**
     * Función que limpia los campos de materiales en el formulario de creación 
     * (borra todos los campos cada vez que se cambia de carrier)
     */
    clearMaterialFields () {
      this.carrierFields.map(material => {
        delete this.form[material.name]
      })
    },
    verifyRangeMaterial (value, name) {
      const selectedField = this.fields[this.selectionIndex(name)]
      if (value >= selectedField.minVal && value <= selectedField.maxVal) selectedField.isValid = true
      else if (value === '') selectedField.isValid = true
      else selectedField.isValid = false
 
      // Comprobar si existe la propiedad "isValid" en alguno de los objetos
      const isValid = this.carrierFields.every(item => !('isValid' in item) || item.isValid)
      
      if (isValid) {
        this.loading.btn = false
        this.showAlertRangeMaterials = false
      } else {
        this.loading.btn = true
        this.showAlertRangeMaterials = true
      }
      return value
    },
    searchShippers (value) {
      const service = ['admin', 'superadmin'].includes(this.mySession.role) ? 'getShippers' : 'getShippersByOrganizationPucon'
      const params = ['admin', 'superadmin'].includes(this.mySession.role) ? null : { organization_id: this.mySession.organization.id }
      return this.materialsService.callService(service, { name: value }, params)
        .then(response => {
          return response.data.map(el => ({...el, text: `${el.id} - ${el.name1 ? el.name1 : el.name}`}))
        })
        .catch(error => console.error(error))
    },
    changeShipper (name, value) {
      if (value) {
        this.getWarehousesByShipper()
        this.getCarriersByShipper(value.id)
      }
      this.form.warehouse = null
      this.form.carrier = null
    },
    getWarehousesByShipper() {
      const params = {
        shipper_id: this.form.shipper.id
      }

      // this.$refs.formRenderMaterial.updateField('warehouse', null)
      this.form.warehouse = null
      this.materialsService.callService('getWarehousesPucon', null, params)
        .then(response => {
          const warehouses = response.data.map(el => ({...el, text: el.name, id: el.id}))
          this.$refs.formRenderMaterial.setSelectOptions('warehouse', warehouses)
        })
        .catch(error => console.error(error))
    },
    getCarriersByShipper(shipperId) {
      // this.$refs.formRenderMaterial.updateField('carrier', null)
      this.form.carrier = null
      const queryParams = {
        company_id: shipperId,
        materials_enabled: true,
        with: 'materials_configuration'
      }
      this.materialsService.callService('getCarriers', queryParams) 
        .then(response => { 
          const carriers = response.data.map(el => ({...el, text: el.name, id: el.code})) 
          this.$refs.formRenderMaterial.setSelectOptions('carrier', carriers) 
        }) 
        .catch(error => console.error(error)) 
    },
    setCarrierAlert (carrier) {
      const carrierQtyDeliveries = {
        COO: 500,
        DPR: 400
      }

      if (carrierQtyDeliveries[carrier.code]) {
        this.alertCarrier.show = true
        this.alertCarrier.n_deliveries = carrierQtyDeliveries[carrier.code]
      } else {
        this.alertCarrier.show = false
        this.alertCarrier.n_deliveries = ''
      }
    }
  }

}
</script>

<style>
.none {
  display: none;
}
.text-help {
  color:brown
}
</style>